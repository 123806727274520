import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { withRouter } from '../common/with-router';
import AuthService from "../services/auth.service";
import fetchData from "../services/error-corrector-api"

function AdminComponent() {

  const [infos, setInfos] = useState([]);
  const [redirect, setRedirect] = useState(false);

  const handle401Response = () => {

  };

  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      setRedirect("/login");
    } else {
      fetchData({serverFct: 'get_admin_data',
      postprocessing: (text) => {
        setInfos(text);
      },
      handle401Response: handle401Response
      });
    }
  }, []);

  if (redirect) {
    return <Navigate to={redirect} />
  } else {
    return (
      <div>
        {infos.map((user) => {
          const date1 = new Date(0);
          date1.setSeconds(user['correction_audio_durations']);
          console.log(user['correction_audio_durations']);
          const correction_audio_durations = date1.toISOString().substring(11, 19);
          const date2 = new Date(0);
          date2.setSeconds(user['correction_audio_durations_corrector']);
          console.log(user['correction_audio_durations_corrector']);
          const correction_audio_durations_corrector = date2.toISOString().substring(11, 19);

          return (
            <div>
              <b>user: {user['user']}</b> <br />
              number of skipped messages: {user['number_skipped_messages']}<br />
              number of correct messages: {user['number_correct_messages_done']}<br />
              number of corrections: {user['number_corrections_done']}<br />
              audio durations: {correction_audio_durations}<br />
              <br />
              number of correct messages as verifier: {user['number_correct_messages_done_corrector']}<br />
              number of corrections as verifier: {user['number_corrections_done_corrector']}<br />
              audio durations as verifier: {correction_audio_durations_corrector}<br />
              <br />
            </div>
          )})
        }
      </div>
    );
  }

}

export default withRouter(AdminComponent);
import React, { useEffect, useRef, useState } from 'react';
import { PersonRemove } from '@mui/icons-material';
import { Button, FormControlLabel, FormGroup, Grid, IconButton, Switch, TextField, Tooltip, InputAdornment } from '@mui/material';
import { Navigate } from 'react-router-dom';

import { ChatMessage } from './messages.components'
import {Message, Correction} from './classes.js'
import { withRouter } from '../common/with-router';
import AuthService from "../services/auth.service";
import fetchData from "../services/error-corrector-api"
import { emptyState, convertStatsToState, convertStateToString, StatisticBox } from "./statistics.components"

import "./correction.component.css";

import { SERVER_URL } from "../constants";

function CorrectorComponent() {
  const scrollRef = useRef(null);
  const [trigger, setTrigger] = useState([42]);

  const [message, setMessage] = useState(null);

  const [redirect, setRedirect] = useState(false);
  const [collectorId, setCollectorId] = useState('');
  const [collectorMail, setCollectorMail] = useState('');

  const [canCorrect, setCanCorrect] = useState(false);

  const [statistics, setStatistics] = useState(emptyState());

  const jsonToChats = (message_json) => {
    if (message_json['message_transcript'] === null) {
      return;
    }

    let corrections = [];
    for (let correction1 of message_json['corrections']) {
      corrections.push(new Correction(correction1['id'], correction1['transcript'], correction1['correction'],
        correction1['correct'], correction1['datetime']));
    }

    console.log(message_json['message_has_audio']);

    let newMessage = new Message(message_json['message_id'], message_json['message_transcript'], message_json['message_transcript'], false, false,
      message_json['message_has_audio'], false, corrections);

    setMessage(newMessage);
  };

  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      setRedirect("/login");
    } else {
      getHistoryCorrector();

      const sse = new EventSource(`${SERVER_URL}/events/corrector-${currentUser.user.id}/`);

      sse.onmessage = (e) => {
        const message_json = JSON.parse(e.data);

        if (message_json['type'] == 'reset') {
          setMessage(null);
          if (!message_json['correct']) {

          }
        } else {
          jsonToChats(message_json);
          setCanCorrect(true);
          setTrigger([...trigger]);
        }
      }

      setTrigger([...trigger]);
    }
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [trigger]);

  const handle401Response = () => {
    setRedirect("/login");
  };

  const getHistoryCorrector = () => {
    fetchData({serverFct: 'get_history_corrector',
      postprocessing: (text) => {
        setCollectorId(text['collector_id']);
        setCollectorMail(text['collector_mail']);
        jsonToChats(text);
        setCanCorrect(text['can_correct']);

        setStatistics(convertStatsToState(text['stats']));
      },
      handle401Response: handle401Response
    });
  };

  const addCorrection = (humanCorrection) => {
    if (canCorrect) {
      let updatedMessage = Object.assign({}, message);
      updatedMessage.corrections.at(-1).correction = humanCorrection;

      const formdataMap = new Map([['correction_id', updatedMessage.corrections.at(-1).id],
        ['correction', humanCorrection]]);
      fetchData({serverFct: 'send_human_correction', formdataMap: formdataMap,
        postprocessing: (text) => {
          const updatedStatistics = new Map(statistics);

          updatedStatistics.set('numberCorrectionsDoneCorrector', updatedStatistics.get('numberCorrectionsDoneCorrector') + 1);
          updatedStatistics.set('correctionAudioDurationsCorrector', updatedStatistics.get('correctionAudioDurationsCorrector') + text['audio_duration']);

          if (text ['correct']) {
            updatedStatistics.set('numberCorrectMessagesDoneCorrector', updatedStatistics.get('numberCorrectMessagesDoneCorrector') + 1);
            setMessage(null);
          } else {
            setMessage(updatedMessage);
          }

          setStatistics(updatedStatistics);
        },
        handle401Response: handle401Response
      });

      setCanCorrect(false);
    }
  };

  const addPerson = (mailAddress) => {
    fetchData({serverFct: 'add_person', formdataMap: new Map([['user', mailAddress]]),
      postprocessing: (text) => {
        setCollectorId(text['collector_id']);
        setCollectorMail(text['collector_mail']);
        jsonToChats(text);
        setCanCorrect(text['can_correct'])
      },
      handle401Response: handle401Response
    });
  };

  const deletePerson = () => {
    fetchData({serverFct: 'delete_person', formdataMap: new Map([['user', collectorId]]),
    postprocessing: (text) => {
      setCollectorId(null);
      setCollectorMail(null);
      setMessage(null);
      setCanCorrect(false);
    }, handle401Response: handle401Response
    });
  };

  let correctionSuggestion = '';
  if (message) {
    correctionSuggestion = message.corrections.length > 1 ? message.corrections.at(-2).correction : message.text;
  }

  if (redirect) {
    return <Navigate to={redirect} />
  } else if (!collectorId) {
    return (
      <div>
        Please input a mail address to connect to data collector. <br />
        <SearchComponent addCorrection={addPerson} disabled={false} defaultText={''} />
      </div>
    );
  } else {
    return (
      <div>
        <div id="chatbox">
          <ul id="chats-list">
            {message &&
              <ChatMessage message={message} index={0} addCorrectionToChats={() => {}}
                showTranscriptMode={true} allCorrectionMode={true}
                handle401Response={handle401Response}
                updateCorrectStateOfChats={() => {}}
                addCorrection={addCorrection} canAddCorrection={canCorrect} correctionSuggestion={correctionSuggestion}
                showCheckbox={false} showRecording={false} showTranscript={false}
                corrector={true} autoplayLatest={true}
              />
            }
            <li ref={scrollRef} />
          </ul>
        </div>

        connected to {collectorMail}
        <IconButton onClick={deletePerson} disabled={false} >
          <PersonRemove />
        </IconButton>

        <StatisticBox state={statistics} />

      </div>
    );
  }

}

// props: addCorrection, disabled, defaultText, key
function SearchComponent(props) {

  const [searchTerm, setSearchTerm] = useState(props.defaultText);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleButtonClick = () => {
    props.addCorrection(searchTerm);
  };

  const handleEnter = (e) => {
    if(e.keyCode == 13) {
      props.addCorrection(searchTerm);
    }
  };

  return (
    <div key={props.key}>
      <TextField
        id="search"
        label=""
        fullWidth
        minRows={2}
        value={searchTerm}
        onChange={handleChange}
        onKeyDown={handleEnter}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <Button disabled={props.disabled} onClick={handleButtonClick}>send</Button>
            </InputAdornment>
        }}
      />
    </div>
  );

}

export default withRouter(CorrectorComponent);
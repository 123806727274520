import React, { useEffect, useRef, useState } from 'react';
import { Mic, Stop } from '@mui/icons-material';
import { FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, MenuItem, Select, Switch, Tooltip } from '@mui/material';
import { Navigate } from 'react-router-dom';

import { ChatMessage } from './messages.components'
import {Message, Correction} from './classes.js'
import useRecorder from './useRecorder';
import { withRouter } from '../common/with-router';
import AuthService from "../services/auth.service";
import fetchData from "../services/error-corrector-api"
import { addMimicUser } from "../services/error-corrector-api"
import { emptyState, convertStatsToState, convertStateToString, StatisticBox } from "./statistics.components"

import "./correction.component.css";

import { SERVER_URL } from "../constants";

function CorrectionComponent() {
  const scrollRef = useRef(null);
  const messageIds = useRef([]);
  const correctionIds = useRef([]);
  const [cursor, setCursor] = useState('default');
  const [chats, setChats] = useState([]);
  const [trigger, setTrigger] = useState([42]);
  const [showTranscriptMode, setShowTranscriptMode] = useState(false);
  const [allCorrectionMode, setAllCorrectionMode] = useState(false);
  const [providedMode, setProvidedMode] = useState(true);
  const [humanMode, setHumanMode] = useState(true);
  const [corrector, setCorrector] = useState(null);
  const [humanHasCorrected, setHumanHasCorrected] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [isUserRecording, setIsUserRecording] = useState(false);
  const [audioURL, resetAudio, isRecording, startRecording, stopRecording, setAudioUrl, recordingStarted] = useRecorder();
  const [statistics, setStatistics] = useState(emptyState());
  const [user, setUser] = useState(null);
  const [mimicUsers, setMimicUsers] = useState([]);
  const [userToMimic, setUserToMimic] = useState(null);
  const [availableMessages, setAvailableMessages] = useState(-1);

  const handle401Response = () => {
    setRedirect("/login");
  }

  const getHistory = ({ triggerTrigger = true } = {}) => {
    const formdataMap = new Map();
    addMimicUser(formdataMap, userToMimic);

    fetchData({serverFct: 'get_history', formdataMap: formdataMap, postprocessing: (text) => {
      let received_chats = [];
      for (const transcript of text['chats']) {
        let corrections = [];
        for (const [i, correction_object] of transcript.corrections.entries()) {
          corrections.push(new Correction(correction_object.id, correction_object.transcript,
            correction_object.correction, correction_object.correct, correction_object.datetime));
        }

        let message = new Message(transcript.id, transcript.transcript, transcript.display_text, transcript.correct, transcript.skipped, transcript.audio_available, transcript.provided, corrections);
        received_chats.push(message);
      }

      setChats(received_chats);
      setCorrector(text['corrector']);
      setHumanHasCorrected(text['human_has_corrected']);

      setStatistics(convertStatsToState(text['stats']));

      if (triggerTrigger) {
        setTrigger([...trigger]);
      }

      }, handle401Response: handle401Response
    });
  }

  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      setRedirect("/login");
    } else {
      setUser(currentUser.user);
      fetchData({serverFct: 'get_all_users', postprocessing: (text) => {
        setMimicUsers(text['users']);
        setAvailableMessages(text['messages_available']);
        }, handle401Response: () => {}
      });

      getHistory(false);

      const sse = new EventSource(`${SERVER_URL}/events/${currentUser.user.id}/`);

      sse.onmessage = (e) => {
        const message_json = JSON.parse(e.data);
        if (message_json['type'] == 'human_correction') {
          getHistory(false);
          return;
        }

        if (message_json['type'] == 'connection') {
          setCorrector(message_json['corrector']);
          return;
        }

        setTimeout(() => {
          if (message_json['type'] == 'message' && !messageIds.current.includes(message_json['message_id'])) {
            getHistory();
          } else if (message_json['type'] == 'correction' && !correctionIds.current.includes(message_json['correction_id'])) {
            getHistory();
          }
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    getHistory(false);
  }, [userToMimic]);

  useEffect(() => {
    if (audioURL != '') {
      fetchData({serverFct: 'transcribe', audioUrlToFetch: audioURL,
        postprocessing: (text) => {
          messageIds.current.push(parseInt(text['message_id']));
          setChats([...chats, new Message(text['message_id'], text['transcript'], null, false, false, true, false, [])]);
          setTrigger([...trigger]);
        },
        handle401Response: handle401Response
      });
    }
  }, [audioURL]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [trigger]);

  useEffect(() => {
    document.body.style.cursor = cursor;
  }, [cursor]);

  const handleRec = async () => {
    if (isUserRecording) {
      stopRecording();
      setIsUserRecording(false);
      setCursor('default');
    } else {
      setCursor('wait');
      startRecording();
      setIsUserRecording(true);
    }
  };

  const loadNewMessagesIfNeeded = (chats, provided) => {
    let todoProvidedMessages = 0;
    if (provided) {
      for (let message of chats) {
        if (message.provided && message.correct === false && message.skipped === false) {
          todoProvidedMessages++;
        }
      }

      console.log('todoProvidedMessages', todoProvidedMessages);

      if (todoProvidedMessages < 1) {
        getHistory();
      }

    }
  };

  const addCorrectionToChats = (text, index, provided) => {
    correctionIds.current.push(parseInt(text['correction_id']));

    const updatedChats = [...chats];
    const updatedStatistics = new Map(statistics);

    if (text['correction_correct']) {
      updatedChats[index].correct = true;
      updatedStatistics.set('numberCorrectMessagesDone', updatedStatistics.get('numberCorrectMessagesDone') + 1);
    }

    updatedStatistics.set('numberCorrectionsDone', updatedStatistics.get('numberCorrectionsDone') + 1);
    updatedStatistics.set('correctionAudioDurations', updatedStatistics.get('correctionAudioDurations') + text['audio_duration']);
    setStatistics(updatedStatistics);

    updatedChats[index].corrections.push(new Correction(text['correction_id'], text['transcript'], text['correction'],
      text['correction_correct'], text['correction_datetime']));
    if (humanMode) {
      setHumanHasCorrected(false);
    }
    setChats(updatedChats);
    loadNewMessagesIfNeeded(updatedChats, provided);
  };

  const searchAndUpdateCorrectStateOfChats = (correct, messageId, correctionId) => {
    for (let message_object of chats) {
      if (messageId != -1) {
        if (message_object.id == messageId) {
          message_object.correct = correct;
          break;
        }
      } else {
        for (let correction_object of message_object.corrections) {
          if (correction_object.id == correctionId) {
            correction_object = correct;
            break;
          }
        }
      }
    }
    setChats([...chats]);
  };

  const searchAndUpdateSkippedStateOfChats = (messageId, provided) => {
    for (let message_object of chats) {
      if (message_object.id == messageId) {
        message_object.skipped = true;
        break;
      }
    }

    const updatedStatistics = new Map(statistics);
    updatedStatistics.set('numberSkippedMessages', updatedStatistics.get('numberSkippedMessages') + 1);
    setStatistics(updatedStatistics);

    setChats([...chats]);
    if (humanMode) {
      setHumanHasCorrected(true);
    }
    loadNewMessagesIfNeeded(chats, provided);
  };

  const updateCorrectStateOfChats = (correct, messageId, correctionId) => {
    searchAndUpdateCorrectStateOfChats(correct, messageId, correctionId);
  };

  const switchTranscriptModeHandler = (event) => {
    setShowTranscriptMode(event.target.checked);
    setTrigger([...trigger]);
  };

  const switchAllCorrectionsModeHandler = (event) => {
    setAllCorrectionMode(event.target.checked);
    setTrigger([...trigger]);
  };

  const switchProvidedModeHandler = (event) => {
    setProvidedMode(event.target.checked);
    setTrigger([...trigger]);
  };

  const switchHumanModeHandler = (event) => {
    setHumanMode(event.target.checked);
    setTrigger([...trigger]);
  };

  const changeUser = (event) => {
    setUserToMimic(event.target.value);
  };

  if (redirect) {
    return <Navigate to={redirect} />
  } else {
    return (
      <div id="correction-component">
        {mimicUsers.length > 0 &&
          <div>
            <FormControl>
              <InputLabel>Select an option</InputLabel>
              <Select
                labelId="dropdown-label"
                id="dropdown"
                value={userToMimic}
                onChange={changeUser}
              >
                <MenuItem value={null}>do not mimic, be {user.email}</MenuItem>

                {mimicUsers.map((otherUser) =>
                  <MenuItem value={otherUser[0]}>{otherUser[1]}</MenuItem>
                )}
              </Select>
            </FormControl>

            available messages: {availableMessages}
          </div>
        }

        <div id="chatbox">
          <ul id="chats-list">
            {chats.map((message, index) =>
              ((providedMode && message.provided) || (!providedMode && !message.provided)) &&
                <ChatMessage message={message} index={index} addCorrectionToChats={addCorrectionToChats}
                  showTranscriptMode={showTranscriptMode} allCorrectionMode={allCorrectionMode}
                  handle401Response={handle401Response}
                  updateCorrectStateOfChats={updateCorrectStateOfChats} searchAndUpdateSkippedStateOfChats={searchAndUpdateSkippedStateOfChats}
                  showCheckbox={!providedMode} showRecording={!humanMode || (corrector && humanHasCorrected)} showTranscript={true}
                  humanCorrector={humanMode}
                  addCorrection={() => {}} canAddCorrection={humanHasCorrected} correctionSuggestion=""
                  corrector={false} userToMimic={userToMimic}
                />
              )
            }
            <li ref={scrollRef} />
          </ul>
        </div>

        <Grid container wrap='nowrap' alignItems="center">
          <Grid item xs={1}>
            {!providedMode &&
              <Tooltip title="record message (please wait with your speech until the microphone symbol was replaced by the stop symbol)">
                <IconButton onClick={handleRec} disabled={humanMode && (!corrector || !humanHasCorrected)} >
                  {recordingStarted ? <Stop fontSize={'large'} /> : <Mic fontSize={'large'} /> }
                </IconButton>
              </Tooltip>
            }
          </Grid>
          <Grid item xs={2}>
            {isUserRecording && !recordingStarted ? <item>recording not ready</item> : <item /> }
          </Grid>
          <Grid item xs={9}>
            <FormGroup row={true}>
              <FormControlLabel control={<Switch onChange={switchAllCorrectionsModeHandler} />} label="show history" />
              <FormControlLabel control={<Switch onChange={switchTranscriptModeHandler} />} label="show transcripts" />
              <FormControlLabel control={<Switch onChange={switchProvidedModeHandler} defaultChecked />} label="show provided messages" />
              <FormControlLabel control={<Switch onChange={switchHumanModeHandler} defaultChecked />} label="human corrector" />
            </FormGroup>
            {humanMode && corrector &&
              <div>Connected to human corrector.</div>
            }
            {humanMode && !corrector &&
              <div>Not connected, wait until a corrector has connected to your account to start the corrections.</div>
            }
            {providedMode &&
              <StatisticBox state={statistics} />
            }
          </Grid>
        </Grid>

      </div>
    );
  }

}

export default withRouter(CorrectionComponent);